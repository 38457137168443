import { render, staticRenderFns } from "./GeneratorDeeplinkBatch.vue?vue&type=template&id=6928f81e"
import script from "./GeneratorDeeplinkBatch.vue?vue&type=script&lang=js"
export * from "./GeneratorDeeplinkBatch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QForm,QCardSection,QSelect,QBanner,QItem,QItemSection,QIcon,QItemLabel,QFile,QBtn});
